<template>
  <v-autocomplete
    v-model="inputVal"
    :items="items"
    :label="label"
    :menu-props="{ offsetY: true, contentClass: 'custom-select-menu' }"
    hide-no-data
    hide-selected
    rounded
    item-text="text"
    item-value="id"
    :autocomplete="autocomplete"
    class="custom-autocomplete-rounded"
  />
</template>

<script>
export default {
  name: 'FormAutocomplete',
  props: {
    value: [String, Number],
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    label: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      datePicker: false
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
