<template>
  <dashboard-content>
    <patient-form
      v-if="patient"
      :client="patient"
    />
  </dashboard-content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardContent from '@/components/dashboard/DashboardContent'
import PatientForm from '@/views/dashboard/patient/PatientForm'

export default {
  name: 'PatientEdit',
  components: { DashboardContent, PatientForm },
  data() {
    return {
      patient: null
    }
  },
  computed: mapGetters('clients', ['client', 'collectionPath']),
  watch: {
    client() {
      if (!this.client.id) {
        this.$router.push({ name: 'patientSelect' })
      }
    }
  },
  async mounted() {
    this.patient =
      this.$route.params.id === this.client.id
        ? this.client
        : await this.fetchClient()
  },
  methods: {
    ...mapActions('clients', ['setClientNew', 'resetClient']),
    fetchClient() {
      return this.$firebase
        .firestore()
        .collection(this.collectionPath)
        .doc(this.$route.params.id)
        .get()
        .then(res => {
          if (!res.exists) {
            return this.resetClient().then(() =>
              this.$router.push({ name: 'patientSelect' })
            )
          }

          const data = { ...res.data(), id: res.id }

          this.setClientNew(data)

          return data
        })
    }
  }
}
</script>
