export default () => {
  return [
    { id: 1, text: 'Black/African American' },
    { id: 2, text: 'White' },
    { id: 3, text: 'Asian' },
    { id: 4, text: 'American Indian/Alaska Native' },
    { id: 5, text: 'Native Hawaiian/Pacific Islander' },
    { id: 6, text: 'Multiracial' }
  ]
}
