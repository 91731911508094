<template>
  <div class="profile">
    <div class="profile-header">
      <h1 class="profile-title">
        {{ formTitle }}
      </h1>
    </div>
    <div :class="['profile-body', { 'is-opened': value.length }]">
      <v-form
        ref="contactForm"
        v-model="contactFormValid"
        @submit.prevent="saveContact"
      >
        <v-expansion-panels
          v-model="value"
          multiple
          flat
          accordion
        >
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header
              class="pa-0"
              hide-actions
            >
              <template v-slot:default="{ open }">
                <div>
                  <div class="d-flex align-center">
                    <div class="expansion-panel-header-title">
                      Contact info
                    </div>
                    <v-spacer />
                    <template v-if="open">
                      <icon-minus />
                    </template>
                    <template v-else>
                      <icon-plus />
                    </template>
                  </div>
                  <div class="expansion-panel-header-subtitle">
                    {{ isUpdating ? 'Change' : 'Add' }} contact info
                  </div>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col cols="12">
                  <form-input
                    v-model="patient.name"
                    style="margin-bottom: 14px;"
                    label="Client name"
                    :rules="rules.name"
                    validate-on-blur
                    autocomplete="name"
                  />
                </v-col>
                <v-col cols="12">
                  <v-row
                    align-center
                  >
                    <v-col class="col py-0">
                      <form-input
                        v-model="patient.phone"
                        style="margin-bottom: 16px;"
                        label="Phone"
                        :rules="rules.phone"
                        validate-on-blur
                        :max-length="20"
                        autocomplete="tel"
                        @input="isPhoneExists = false"
                      />
                    </v-col>
                    <v-col class="col-12 col-sm-auto py-0 checkbox-wrap">
                      <v-checkbox
                        class="custom-checkbox mt-0"
                        :label="channelSms ? 'Preferred' : ''"
                        :value="channelSms"
                        :input-value="channelSms"
                        :readonly="channelSms"
                        @change="channelChange('sms')"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  class="mt-2"
                  cols="12"
                >
                  <v-row align-center>
                    <v-col class="col py-0">
                      <form-input
                        v-model="patient.email"
                        style="margin-bottom: 14px;"
                        label="Email"
                        type="email"
                        :rules="rules.email"
                        validate-on-blur
                        autocomplete="email"
                        @input="isEmailExists = false"
                      />
                    </v-col>
                    <v-col class="col-12 col-sm-auto py-0 checkbox-wrap">
                      <v-checkbox
                        class="custom-checkbox mt-0"
                        :label="channelEmail ? 'Preferred' : ''"
                        :value="channelEmail"
                        :input-value="channelEmail"
                        :readonly="channelEmail"
                        @change="channelChange('email')"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <form-input
                    v-model="patient.caregiverName"
                    label="Caregiver name (if applicable)"
                    autocomplete="name"
                  />
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-model="caregiverAsPatient"
                    label="Same as client"
                    :input-value="patient.caregiverAsPatient"
                    class="custom-checkbox mt-0"
                    @change="setCaregiverFromPatient"
                  />
                </v-col>
                <v-col cols="12">
                  <form-input
                    v-model="patient.caregiverPhone"
                    label="Caregiver Phone"
                    :disabled="caregiverAsPatient"
                    autocomplete="tel"
                  />
                </v-col>
                <v-col cols="12">
                  <form-input
                    v-model="patient.caregiverEmail"
                    label="Caregiver Email"
                    :disabled="caregiverAsPatient"
                    :rules="rules.caregiverEmail"
                    autocomplete="email"
                  />
                </v-col>
                <v-col class="col-12 d-flex justify-end mt-4">
                  <v-btn
                    x-large
                    type="submit"
                    color="primary"
                    min-width="184"
                  >
                    {{ submitButtonText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header
              class="pa-0"
              hide-actions
            >
              <template v-slot:default="{ open }">
                <div>
                  <div class="d-flex align-center">
                    <div class="expansion-panel-header-title">
                      Personal info (optional)
                    </div>
                    <v-spacer />
                    <template v-if="open">
                      <icon-minus />
                    </template>
                    <template v-else>
                      <icon-plus />
                    </template>
                  </div>
                  <div class="expansion-panel-header-subtitle">
                    {{ isUpdating ? 'Change' : 'Add' }} contact info
                  </div>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col cols="12">
                  <FormDatePicker
                    v-model="patient.dob"
                    label="Date of birth"
                  />
                </v-col>
                <v-col cols="12">
                  <FormAutocomplete
                    v-model="patient.gender"
                    :items="genders"
                    label="Gender"
                    autocomplete="patient-gender"
                  />
                </v-col>
                <v-col cols="12">
                  <FormAutocomplete
                    v-model="patient.race"
                    :items="races"
                    label="Race"
                    autocomplete="patient-race"
                  />
                </v-col>
                <v-col cols="12">
                  <FormAutocomplete
                    v-model="patient.ethnicity"
                    :items="ethnicities"
                    label="Ethnicity"
                    autocomplete="patient-ethnicity"
                  />
                </v-col>
                <v-col cols="12">
                  <FormDatePicker
                    v-model="patient.caregiverDob"
                    label="Caregiver date of birth"
                  />
                </v-col>
                <v-col cols="12">
                  <FormAutocomplete
                    v-model="patient.caregiverGender"
                    :items="genders"
                    label="Caregiver gender"
                    autocomplete="patient-caregiver-gender"
                  />
                </v-col>
                <v-col cols="12">
                  <FormAutocomplete
                    v-model="patient.caregiverRace"
                    :items="caregiverRaces"
                    label="Caregiver race"
                    autocomplete="patient-caregiver-race"
                  />
                </v-col>
                <v-col cols="12">
                  <FormAutocomplete
                    v-model="patient.caregiverEthnicity"
                    :items="ethnicities"
                    label="Caregiver ethnicity"
                    autocomplete="patient-caregiver-ethnicity"
                  />
                </v-col>
                <v-col class="col-12 d-flex justify-end mt-7">
                  <v-btn
                    x-large
                    type="submit"
                    color="primary"
                    min-width="184"
                  >
                    {{ submitButtonText }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import moment from '@/plugins/moment'
import FormInput from '@/components/form/FormInput'
import FormDatePicker from '@/components/form/FormDatePicker'
import FormAutocomplete from '@/components/form/FormAutocomplete'
import IconPlus from '@/components/icons/IconPlus'
import IconMinus from '@/components/icons/IconMinus'
import ethnicities from '@/kit/dictionaries/ethnicities'
import genders from '@/kit/dictionaries/genders'
import caregiverEthnicities from '@/kit/dictionaries/caregiverEthnicities'
import races from '@/kit/dictionaries/races'
import caregiverRaces from '@/kit/dictionaries/caregiverRaces'
import emailValidation from '@/helpers/emailValidation'

export default {
  name: 'PatientForm',
  components: {
    FormInput,
    IconPlus,
    IconMinus,
    FormDatePicker,
    FormAutocomplete
  },
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      moment,
      patient: { ...this.client },
      value: [0],
      contactFormValid: false,
      ethnicities: ethnicities(),
      genders: genders(),
      caregiverEthnicities: caregiverEthnicities(),
      races: races(),
      caregiverRaces: caregiverRaces(),
      rules: {
        name: [v => !!v || 'Name is required'],
        phone: [
          v =>
            !this.channelSms || !!v || 'Phone is required if set as preferred',
          v =>
            !this.channelSms ||
            !v ||
            /\d{10}/.test(v.replace(/\D/g, '')) ||
            'Phone number must be valid',
          () => !this.isPhoneExists || 'User with this phone already exists'
        ],
        email: [
          v =>
            !this.channelEmail ||
            !!v ||
            'Email is required if set as preferred',
          v => !this.channelEmail || emailValidation(v),
          () => !this.isEmailExists || 'User with this email already exist'
        ],
        caregiverEmail: [
          v => !v || /.+@.+\..+/.test(v) || 'Email must be valid'
        ]
      },
      isPhoneExists: false,
      isEmailExists: false,
      datePiker: false
    }
  },
  computed: {
    formTitle() {
      return this.patient.name || 'Client name'
    },
    channelSms() {
      return this.patient.channel === 'sms'
    },
    channelEmail() {
      return this.patient.channel === 'email'
    },
    caregiverAsPatient: {
      get() {
        return this.patient.caregiverAsPatient
      },
      set(value) {
        this.patient.caregiverAsPatient = value
      }
    },
    isUpdating() {
      return !!this.client.id
    },
    submitButtonText() {
      return this.isUpdating ? 'Update' : 'Add'
    }
  },
  watch: {
    'patient.phone': function() {
      if (this.caregiverAsPatient) {
        this.patient.caregiverPhone = this.patient.phone
      }
    },
    'patient.email': function() {
      if (this.caregiverAsPatient) {
        this.patient.caregiverEmail = this.patient.email
      }
    }
  },
  methods: {
    ...mapMutations('app', ['loading']),
    ...mapActions('clients', ['writeClient', 'getClient']),
    setCaregiverFromPatient() {
      if (this.caregiverAsPatient) {
        this.patient.caregiverPhone = this.patient.phone
        this.patient.caregiverEmail = this.patient.email
      }
    },
    channelChange(name) {
      this.patient.channel = name
      this.validate('contactForm')
    },
    async validate(ref) {
      const clientEmailExists = await this.getClient({
        email: this.patient.email
      })
      /**
       * Phone number existence validation no longer needed
       */
      // const clientPhoneExists = await this.getClient({
      //   phone: this.patient.phone
      // })

      this.isEmailExists =
        !!clientEmailExists && clientEmailExists.id !== this.client.id
      /**
       * Phone number existence validation no longer needed
       */
      // this.isPhoneExists =
      //   !!clientPhoneExists && clientPhoneExists.id !== this.client.id

      this.$refs[ref].validate()
    },
    async saveContact() {
      await this.validate('contactForm')

      if (this.contactFormValid) {
        this.loading(true)
        for (const p in this.patient) {
          if (this.patient[p] === undefined) {
            this.patient[p] = null
          }
        }
        this.writeClient(this.patient)
          .then(() => {
            this.isUpdating
              ? this.$notify({
                  text: 'Client was successfully updated'
                })
              : this.$router.push({ name: 'patientSelected' })
          })
          .catch(e =>
            this.$notify({
              type: 'err',
              text: e.message
            })
          )
          .finally(() => this.loading(false))
      }
    }
  }
}
</script>

<style scoped lang="scss">
.checkbox-wrap {
  width: 110px;
}

.v-input__control {
  margin-bottom: 4px !important;
}
</style>
