<template>
  <v-text-field
    class="custom-text-field"
    :value="value"
    :label="label"
    filled
    height="64"
    :type="type"
    :rules="rules"
    :maxlength="maxLength"
    :validate-on-blur="validateOnBlur"
    :readonly="readonly"
    :disabled="disabled"
    :autocomplete="autocomplete"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    autocomplete: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validateOnBlur: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    maxLength: {
      type: Number,
      default: 255
    }
  }
}
</script>
