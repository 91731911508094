<template>
  <v-menu
    v-model="datePicker"
    :close-on-content-click="false"
    :nudge-bottom="-16"
    transition="scale-transition"
    offset-y
    min-width="auto"
    max-width="100%"
  >
    <template v-slot:activator="{ on, attrs }">
      <form-input
        v-model="inputValText"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="inputVal"
      :width="$vuetify.breakpoint.xsOnly ? 252 : 290"
      :max="moment().format('YYYY-MM-DD')"
      @input="datePicker = false"
    />
  </v-menu>
</template>

<script>
import FormInput from '@/components/form/FormInput'
import moment from '@/plugins/moment'

export default {
  name: 'FormDatePicker',
  components: { FormInput },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      moment,
      datePicker: false
    }
  },
  computed: {
    inputValText: {
      get() {
        return this.inputVal
          ? moment(this.inputVal, 'YYYY-MM-DD').format('MM-DD-YYYY')
          : ''
      },
      set(val) {
        this.value = val
      }
    },
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
